<template>
    <div>
        <PrizificPageBuilder
            v-if="useActualPage(EComponents.AGE_GATE_PAGE)"
            :page="useActualPage(EComponents.AGE_GATE_PAGE)"
        />
    </div>
</template>

<script setup>
import { usePagesStore } from "@/store/pages";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

const pageStore = usePagesStore();
//
onServerPrefetch(async () => {
    try {
        await pageStore.getPageFromServer(EComponents.AGE_GATE_PAGE);
    } catch (e) {
        console.error("On Server Prefetch error in draw page (age-gate)", e);
    }
});

onMounted(async () => {
    try {
        if (!pageStore.getPage(EComponents.AGE_GATE_PAGE)) {
            await pageStore.getPageFromServer(EComponents.AGE_GATE_PAGE);
        }
    } catch (e) {
        console.error("On Server Prefetch error in draw page (age-gate)", e);
    }
});

const route = useRoute();
</script>

<style lang="scss"></style>
